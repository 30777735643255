<!--
 * @Author: Yran
 * @Date: 2021-09-09 10:05:35
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-19 14:24:16
-->
<template>
  <div class="w100 h100 detail_table">
    <!-- 模块顶部 -->
    <div class="module_title flexBetweenCenter">
      <span>
        <span class="title_content"> {{ $attrs.moduleTitle || '多维度报表' }} </span>
      </span>
      <span class="select_group">
        <span class="select_label">细分维度：</span>
        <SelectMultiple style="min-width: 200px" v-model:value="tableDatas.groupBy" :select-data="tableDatas.groupBy" :select-options="getGroupByList" :allow-clear="true" mode="multiple" placeholder="请选择细分维度" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="value" select-option-label="label" @change="groupByChange" :disabled="tableDatas.tableLoading" />
        <TempSelect :temp-list="tempList" :show-add-icon="false" @getTempName="getTempNameValue" @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" :disabled="tableDatas.tableLoading" />
        <a-button v-if="false">
          <template #icon><CloudDownloadOutlined /></template>
          下载报表
        </a-button>
      </span>
    </div>
    <div class="table_wrapper w100">
      <home-table :table-data="tableDatas.tableData" :table-columns="tableColumns" :pagination="tablePagination" :table-sorter="tableSorter" :table-loading="tableDatas.tableLoading || tableSetLoading" @table-change="onTableChange"></home-table>
    </div>
    <!-- 自定义列弹窗 -->
    <customize-column :show-check="false" :selected-indicator="selectedIndicator" :fixed-list="fixedList" :column-name="editTempName" title-tips="（勾选媒体渠道的指标，请在对应的渠道下查看）" :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList" @get-temp-data="getTempData" />
  </div>
</template>

<script>
import { getAllTemp, getChooseTemp, getTempName } from '@/api/material/material.js';
import { unique } from '@/utils/utils.js';
import { saveNotRestriction, postUpdateTemp, postDeleteTemp } from '@/api/customize/index.js';
import { CloudDownloadOutlined } from '@ant-design/icons-vue';
import HomeTable from '../../HomeTable.vue';
import CustomizeColumn from '@/components/Customize/customizeColumnModal.vue';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import TempSelect from '@/components/Customize/templateSelect.vue';
export default {
  emits: ['setTableCondition'],
  name: '',
  components: { HomeTable, SelectMultiple, TempSelect, CloudDownloadOutlined, CustomizeColumn },
  inject: ['tableDatas', 'tablePagination', 'tableSorter'],
  data() {
    return {
      //   tableData: [], // 表格数据
      activeTemp: '默认指标', // 当前选中模板
      dimensionList: [
        { value: 'date', label: '日期' },
        // { value: '', label: '月' },
        // { value: '', label: '一级行业' },
        { value: 'dept_name', label: '运营部门' },
        { value: 'customer_name', label: '客户' },
        { value: 'company', label: '主体' },
        { value: 'product_name', label: '产品' },
        { value: 'operator_name', label: '运营个人' },
        // { value: '', label: '账号ID' },
        // { value: '', label: '账户名称' },
        // { value: '', label: '推广目标类型' },
        // { value: '', label: '版位' },
      ], // 细分维度列表
      selectedIndicator: [], // 已选中指标
      fixedList: [], // 固定指标
      editTempName: '', // 编辑模板名称
      menuList: [], // 全部指标
      tempList: [], // 全部模板
      modalVisible: false, // 是否打开自定义指标
      tableColumns: [],
      tableSetLoading: false,
      tableSourceColumns: [],
    };
  },
  computed: {
    getGroupByList() {
      if (this.$attrs.filterCondition.channel) {
        let channelDimensionList = {
          TT: [
            { value: 'campaign_id', label: '广告组ID' },
            { value: 'campaign_name', label: '广告组名称' },
            { value: 'ad_id', label: '计划ID' },
            { value: 'ad_name', label: '计划名称' },
            { value: 'creative_id', label: '创意ID' },
            { value: 'creative_name', label: '创意名称' },
          ],
          KS: [
            { value: 'campaign_id', label: '广告计划ID' },
            { value: 'campaign_name', label: '广告计划名称' },
            { value: 'unit_id', label: '广告组ID' },
            { value: 'unit_name', label: '广告组名称' },
            { value: 'creative_id', label: '广告创意ID' },
            { value: 'creative_name', label: '广告创意名称' },
          ],
          GDT: [
            { value: 'campaign_id', label: '计划ID' },
            { value: 'campaign_name', label: '计划名称' },
            { value: 'adgroup_id', label: '广告ID' },
            { value: 'adgroup_name', label: '广告名称' },
            { value: 'ad_id', label: '创意ID' },
            { value: 'ad_name', label: '创意名称' },
          ],
        };
        return [...this.dimensionList, ...channelDimensionList[this.$attrs.filterCondition.channel]];
      } else {
        return this.dimensionList;
      }
    },
  },
  async created() {
    await this.getAllTemp();
    this.getTempName();
  },
  methods: {
    groupByChange(value) {
      if (value?.length == 0) {
        this.tableDatas.groupBy = ['date'];
        this.$message.error('至少选择一个细分维度！');
      } else if (value?.length > 3) {
        this.tableDatas.groupBy = this.tableDatas.groupBy.slice(0, 3);
        this.$message.error('最多只能选择三个细分维度！');
      }
      this.getTempName();
    },
    //表格分页
    onTableChange(tablePagination, tableSorter) {
      this.tableSorter = tableSorter;
      this.tableSorter.sortBy = [tableSorter.orderType];
      this.tablePagination.change = new Array();
    },
    /** 获取素材库所有指标
     * @param {*}
     * @return {*}
     */
    async getAllTemp() {
      let res = await getAllTemp();
      res.list?.forEach(item => {
        item.info?.forEach(childItem => {
          childItem.customConfigId = childItem.id;
          childItem.info?.forEach(info => {
            info.customConfigId = info.id;
          });
        });
      });
      this.menuList = res.list;
      if (this.activeTemp && this.activeTemp !== '默认指标') await this.getChooseTemp();
    },
    /** 获取模板名称
     * @param {*}
     * @return {*}
     */
    getTempName() {
      getTempName().then(res => {
        if (res.code == 0) {
          this.activeTemp = '默认指标';
          this.tempList = res.list;
          this.tempList.unshift({ tempName: '默认指标' });
          if (this.activeTemp && this.activeTemp !== '默认指标') {
            this.getChooseTemp();
          } else {
            const list = this.menuList.filter(item => {
              return item.key == 'SHARE_DATA';
            })?.[0]?.info;
            this.setColumn(list);
          }
        }
      });
    },

    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getChooseTemp(tempName) {
      if ((this.activeTemp && this.activeTemp !== '默认指标') || (tempName && tempName !== '默认指标')) {
        let res = await getChooseTemp(tempName || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);
      }
    },
    /** 模板下拉变化
     * @param {*} tempName
     * @return {*}
     */
    getTempNameValue(tempName) {
      this.activeTemp = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标') {
        this.getChooseTemp();
      } else {
        const list = this.menuList.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);
      }
    },
    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      this.tableSetLoading = true;
      const _this = this;
      setTimeout(() => {
        _this.tableColumns = _.cloneDeep(_this.tableSourceColumns);

        arr?.forEach(item => {
          const itemChannel = item.oneColumnKeys?.split('_')[0];
          if ((_this.$attrs.filterCondition.channel && _this.$attrs.filterCondition.channel.indexOf(itemChannel) !== -1) || itemChannel === 'SHARE') {
            _this.tableColumns?.push({
              title: item.columnValue.indexOf('率') !== -1 ? item.columnValue + '(%)' : item.columnValue,
              dataIndex: item.columnKey,
              sorter: true,
              sortDirections: ['descend', 'ascend'],
              slots: { customRender: item.columnKey },
            });
          }
        });
        let groupByList = _.cloneDeep(_this.tableDatas.groupBy).reverse();
        groupByList.forEach(item => {
          let itemObj = _this.getGroupByList.find(group => {
            return group.value == item;
          });
          _this.tableColumns.unshift({
            title: itemObj.label.indexOf('率') !== -1 ? itemObj.label + '(%)' : itemObj.label,
            dataIndex: item,
            sorter: itemObj.label == '日期',
            sortDirections: ['descend', 'ascend'],
            slots: { customRender: item },
          });
        });
        _this.tableColumns = unique(_this.tableColumns);
        _this.tableSetLoading = false;
      }, 10);
    },
    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      this.operatType = 'edit';
      this.editTempName = tempName;
      if (this.editTempName && this.editTempName !== '默认指标') await this.getChooseTemp(tempName);
      this.modalVisible = true;
    },
    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      this.operatType = 'delete';
      postDeleteTemp([tempName]).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.selectedIndicator = [];
          this.getTempName();
          const list = this.menuList.filter(item => {
            return item.key == 'SHARE_DATA';
          })?.[0]?.info;
          this.setColumn(list);
        }
      });
    },
    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      this.operatType = 'add';
      this.editTempName = null;
      if (this.menuList.length > 0) {
        this.selectedIndicator = this.menuList.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.modalVisible = true;
      } else {
        this.$message.error('当前口径无可设置指标，请联系后台管理员！');
      }
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    getTempData(tempUserCustoms) {
      if (tempUserCustoms) {
        this.tempUserCustoms = tempUserCustoms;
        let postData = {};
        if (this.operatType === 'add') {
          postData = {
            tempUserCustoms: tempUserCustoms,
          };
          saveNotRestriction(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        } else if (this.operatType === 'edit') {
          postData = {
            oldTempName: this.activeTemp,
            tempUserCustoms: tempUserCustoms,
          };
          postUpdateTemp(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
      // this.modalVisible = false;
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
  },
};
</script>

<style scoped lang="less">
.detail_table {
  display: flex;
  flex-direction: column;
  .ant-select {
    width: 108px;
  }
  .chart_wrapper {
    flex-grow: 1;
  }
  .table_wrapper {
    padding-top: 0;
  }
}
</style>
