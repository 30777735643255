<!--
 * @Author: Yran
 * @Date: 2021-09-08 09:26:50
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-25 16:51:01
-->
<template>
  <div class="home_detail">
    <div class="detail_search_bar w100 home_card">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="运营"></a-tab-pane>
      </a-tabs>
      <div class="filter-container w100 flexJustifyBetween">
        <a-form class="filter-content" ref="filterForm" :rules="filterRules" :model="filterCondition">
          <a-form-item class="mb0" :wrapper-col="{ span: 23 }" :label-col="{ span: 1 }" label="筛选">
            <a-row class="filter-row">
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <a-select style="width: 216px" placeholder="请选择媒体渠道" v-model:value="filterCondition.channel" :max-tag-count="1" :show-arrow="true" :options="channelList" @change="channelChange"> </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.industry" :select-data="filterCondition.industry" :select-options="industryList" :allow-clear="true" mode="multiple" placeholder="请选择行业" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="value" select-option-label="label" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.dept" :select-data="filterCondition.dept" :select-options="filterDepIdList" :allow-clear="true" mode="multiple" placeholder="请选择部门" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="deptId" select-option-label="name" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.customerId" :select-data="filterCondition.customerId" :select-options="companyNameOptions" :allow-clear="true" mode="multiple" placeholder="请选择客户名称" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="value" select-option-label="label" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.productId" :select-data="filterCondition.productId" class="select-tagmode" :select-options="projectOptions" :allow-clear="true" mode="multiple" placeholder="请选择产品" option-label-prop="label" :filter-option="false" :max-tag-count="1" :show-arrow="true" :dropdown-match-select-width="false" select-option-value="value" select-option-label="label" @search="value => onSelectSearch(value, 'projectOptions', projectStorage)" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.operaterId" :select-data="filterCondition.operaterId" class="select-tagmode" :select-options="distributionPerson" :allow-clear="true" mode="multiple" placeholder="请选择运营" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :dropdown-match-select-width="false" select-option-value="userId" select-option-label="username" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.advertiserId" :select-data="filterCondition.advertiserId" class="select-tagmode" :select-options="mediaAccountOptions" :allow-clear="true" mode="multiple" placeholder="请输入媒体账号或者媒体账号ID" :filter-option="false" option-label-prop="label" :max-tag-count="1" :show-arrow="true" :dropdown-match-select-width="false" select-option-value="value" select-option-label="label" @search="value => onSelectSearch(value, 'mediaAccountOptions', mediaAccountStorage)" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <!-- <SelectMultiple style="width: 216px" v-model:value="filterCondition.companyId" :select-data="filterCondition.companyId" class="select-tagmode" :select-options="invoiceNameOptions" :allow-clear="true" mode="multiple" placeholder="请选择开户主体" option-label-prop="label" :filter-option="false" :max-tag-count="1" :show-arrow="true" :dropdown-match-select-width="false" select-option-value="value" select-option-label="label" @search="value => onSelectSearch(value, 'invoiceNameOptions', invoiceNameStorage)" /> -->
                  <a-select style="width: 216px" placeholder="请选择开户主体" v-model:value="filterCondition.companyId" allow-clear :show-arrow="true" :options="invoiceNameOptions" option-label-prop="label" :filter-option="false" show-search @search="value => onSelectSearch(value, 'invoiceNameOptions', invoiceNameStorage)"> </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" v-model:value="filterCondition.promotedObjectType" :select-data="filterCondition.promotedObjectType" placeholder="请选择推广目标类型" option-filter-prop="label" mode="multiple" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="promotionGoalList" select-option-value="value" select-option-label="label" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <SelectMultiple style="width: 216px" class="select-tagmode" mode="multiple" v-model:value="filterCondition.sitSet" :select-data="filterCondition.sitSet" placeholder="请选择版位" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" :allow-clear="true" :dropdown-match-select-width="false" :select-options="getSitSetOptions" select-option-value="value" select-option-label="label" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <a-select style="width: 216px" placeholder="请选择自定义筛选" v-model:value="filterCondition.channelOption" allow-clear :show-arrow="true" :options="channelOptionList[filterCondition.channel]"> </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4" v-show="filterCondition.channelOption">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <a-input style="width: 216px" placeholder="请输入自定义筛选的值" v-model:value="filterCondition[filterCondition.channelOption]" allow-clear> </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item class="mb0" :wrapper-col="{ span: 23 }" :label-col="{ span: 1 }" label="时间范围">
            <a-row>
              <a-col :span="24">
                <a-form-item :wrapper-col="{ span: 18 }">
                  <a-range-picker v-model:value="filterCondition.uploadTime" style="height: 32px" :placeholder="['开始日期', '结束日期']" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" :allow-clear="false" />
                  <a-switch class="ml20 mr10" v-model:checked="showComparison" @change="setCompare" />对比
                  <a-range-picker class="ml10" v-if="showComparison" v-model:value="filterCondition.compareTime" style="height: 32px" :placeholder="['开始日期', '结束日期']" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" @change="compareTimeChange" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <!-- <a-row>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 18 }" :label-col="{ span: 6 }" label="时间维度">
                <a-input v-model:value="filterCondition.materialIds" placeholder="请输入素材ID" allow-clear @keydown="onInputKeyDown" :disabled="isDisabled(filterCondition.materialIdList)"> </a-input>
              </a-form-item>
            </a-col>
          </a-row> -->
        </a-form>
        <div class="filter-buttons flexBetweenCenter">
          <a-button class="btn filter-btn" type="primary" @click="onFilterBtnClick" :disabled="tableDatas.tableLoading">筛选</a-button>
          <a-button class="btn cancen-btn" @click="onResetBtnClick">重置</a-button>
        </div>
      </div>
    </div>
    <template v-for="c in layout" :key="c.component">
      <component :is="c.component" v-bind="c"> </component>
    </template>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import getLayout from './js/homeLayout.js';
import { channelList, detailIndustryList, siteSetOptions, promotionGoalList } from '@/config/globalData.js';
import { getListByDeptTypesData } from '@/api/base/department.js';
import { getCustomerDistinctListData } from '@/api/customer/customer.js';
import { getProjectListByCompanyName } from '@/api/base/project.js';
import { getUserList, getCustomersInvoiceList } from '@/api/mediaAccount/mediaAccount.js';
import { getColumns } from '@/api/echarts/index.js';
import { getMediaAccountListData } from '@/api/report';
import { getDetailReportAgg, getUserChannel } from '@/api/home/detail.js';

import SelectMultiple from '@/components/selectMultiple/index.vue';
import HomeDetailTables from './components/detailTable/index.vue';
import HomeDetailChart from './components/detailChart/index.vue';
export default {
  name: 'HomeDetail',
  components: { SelectMultiple, HomeDetailTables, HomeDetailChart },
  provide() {
    return { tableDatas: this.tableDatas, tablePagination: this.tablePagination, chartFilterData: this.chartFilterData, tableSorter: this.tableSorter };
  },
  data() {
    const role = 'OPERATIONLEADERDETAIL';

    return {
      layoutChart: [], // 详情中的图表
      layoutTable: [], // 详情中的表格
      dateRangeFormat: 'YYYY-MM-DD', // 时间格式化
      detailIndustryList, // 行业列表
      industryList: [], // 行业列表
      filterDepIdList: [], // 部门列表
      companyNameOptions: [], // 客户列表
      projectOptions: [], // 产品列表
      projectStorage: [], // 产品全部列表
      distributionPerson: [], // 运营人员列表
      invoiceNameOptions: [], // 开户主体下拉列表
      invoiceNameStorage: [], // 开户主体所有列表
      promotionGoalList, // 推广目标类型列表
      siteSetOptions, // 素材版位列表
      mediaAccountStorage: [], // 媒体账号所有列表
      mediaAccountOptions: [], // 媒体账号前100列表
      showComparison: false, // 是否开启比对
      channel: 'GDT',
      activeKey: '1', // tab选中
      echartsOptions: {}, // 图表配置
      filterRules: [],
      chartFilterData: { filter1: 'show', filter2: 'cost' },
      chartFilter: [], // 判断图表上的筛选变化
      filterCondition: {
        channel: 'GDT',
        uploadTime: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
      },
      tableDatas: {
        tableData: [],
        tableLoading: false,
        chartsLoading: false,
        groupBy: ['date'],
      },
      chartsData: [],
      layout: getLayout(role), // 媒体渠道列表
      channelList,
      tablePagination: {
        limit: '31',
        page: '1',
        pageSize: 31,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['8', '16', '31', '51'],
      },
      tableSorter: { order: 'asc', sortBy: ['date'] },
      channelOptionList: {
        GDT: [
          { label: '计划ID', value: 'campaignId' },
          { label: '计划名称', value: 'campaignName' },
          { label: '广告ID', value: 'adgroupId' },
          { label: '广告名称', value: 'adgroupName' },
          { label: '创意ID', value: 'adId' },
          { label: '创意名称', value: 'adName' },
        ],
        TT: [
          { label: '广告组ID', value: 'campaignId' },
          { label: '广告组名称', value: 'campaignName' },
          { label: '计划ID', value: 'adId' },
          { label: '计划名称', value: 'adName' },
          { label: '创意ID', value: 'creativeId' },
          { label: '创意名称', value: 'creativeName' },
        ],
        KS: [
          { label: '广告计划ID', value: 'campaignId' },
          { label: '广告计划名称', value: 'campaignName' },
          { label: '广告组ID', value: 'unitId' },
          { label: '广告组名称', value: 'unitName' },
          { label: '广告创意ID', value: 'creativeId' },
          { label: '广告创意名称', value: 'creativeName' },
        ],
      },
    };
  },
  computed: {
    getSitSetOptions() {
      return this.siteSetOptions.filter(item => {
        return item.channel == this.filterCondition.channel;
      });
    },
  },
  watch: {
    'chartFilterData.change'(newVal) {
      this.setChartOptions();
    },
    'tableDatas.groupBy'(newVal) {
      this.getData();
    },
    'tablePagination.change'(newVal) {
      this.getData();
    },
  },
  created() {
    this.getListSearchCaliber();
    this.getDeptListByType();
    this.getCustomerList();
    this.getProjectListFilter();
    this.getDistributionPerson();
    this.getCustomersList();
    this.industryList = _.cloneDeep(this.detailIndustryList[this.filterCondition.channel]);
    this.layoutChart = this.layout.find(item => {
      return item.sortIndex == 1;
    })?.children;
    this.layoutTable = this.layout.find(item => {
      return item.sortIndex == 2;
    })?.children;
    this.echartsOptions = _.cloneDeep(this.layoutChart[0].echartsOptions);
    this.layoutTable[0].filterCondition = this.filterCondition;
    this.tablePagination.pageSize = this.filterCondition.uploadTime[1].diff(this.filterCondition.uploadTime[0], 'days') + 2;
    this.getUserChannel().then(() => {
      this.getMediaAccountList();
      this.getData();
      this.getChartData();
    });
  },
  methods: {
    channelChange() {
      this.filterCondition.industry = [];
      this.filterCondition.sitSet = [];
      if (this.tableDatas.groupBy.length > 1 || this.tableDatas.groupBy[0] != 'date') {
        this.tableDatas.groupBy = ['date'];
      }
      this.industryList = _.cloneDeep(this.detailIndustryList[this.filterCondition.channel]);
    },
    /** 根据用户选择渠道
     * @param {*}
     * @return {*}
     */
    async getUserChannel() {
      let res = await getUserChannel();
      this.filterCondition.channel = res.data;
      this.channel = res.data;
    },
    /** 对比选项变化
     * @param {*}
     * @return {*}
     */
    setCompare(checked) {
      if (checked) {
        let uploadTime = _.cloneDeep(this.filterCondition.uploadTime);
        let diffDate = this.filterCondition.uploadTime[1].diff(this.filterCondition.uploadTime[0], 'days') + 1;
        this.filterCondition.compareTime = [uploadTime[0].subtract(diffDate, 'days'), uploadTime[1].subtract(diffDate, 'days')];
      } else {
        this.filterCondition.compareTime = [];
      }
    },
    /** 对比时间变化
     * @param {*}
     * @return {*}
     */
    compareTimeChange(time) {
      if (time[1].diff(time[0], 'days') !== this.filterCondition.uploadTime[1].diff(this.filterCondition.uploadTime[0], 'days')) {
        this.$message.error('请选择与上传时间范围想等的时间段！');
        this.filterCondition.compareTime = [];
      } else if (this.timeCross(time, this.filterCondition.uploadTime)) {
        this.$message.error('请勿选择交叉时间范围！');
        this.filterCondition.compareTime = [];
      }
    },
    /** 返回是否时间存在交叉
     * @param {*} time1
     * @param {*} time2
     * @return {*}
     */
    timeCross(time1, time2) {
      if (time1[0] < time2[0]) {
        return time1[1] >= time2[0];
      } else {
        return time2[1] >= time1[0];
      }
    },
    /** 设置图表配置项
     * @param {*}
     * @return {*}
     */
    setChartOptions() {
      let filter1 = this.layoutChart[0].selectOptions1.filter(item => {
        return item.value == this.chartFilterData.filter1;
      })?.[0];
      let filter2 = this.layoutChart[0].selectOptions2.filter(item => {
        return item.value == this.chartFilterData.filter2;
      })?.[0];
      let filterObj = { 0: filter1, 1: filter2 };
      this.layoutChart[0].echartsOptions.xAxis.forEach(item => (item.data = []));
      this.layoutChart[0].echartsOptions.series.forEach(item => (item.data = []));
      Object.keys(filterObj).forEach(key => {
        this.layoutChart[0].echartsOptions.series[key].name = `${this.filterCondition.uploadTime[0].format('MM-DD')}~${this.filterCondition.uploadTime[1].format('MM-DD')} ${filterObj[key].label}`;
        this.layoutChart[0].echartsOptions.legend.data[key] = this.layoutChart[0].echartsOptions.series[key].name;
        if (this.showComparison) {
          this.layoutChart[0].echartsOptions.series[key - 0 + 2].name = `${this.filterCondition.compareTime[0].format('MM-DD')}~${this.filterCondition.compareTime[1].format('MM-DD')} ${filterObj[key].label}`;
          this.layoutChart[0].echartsOptions.legend.data[key - 0 + 2] = this.layoutChart[0].echartsOptions.series[key - 0 + 2].name;
        } else {
          this.layoutChart[0].echartsOptions.series[key - 0 + 2].name = '';
          this.layoutChart[0].echartsOptions.legend.data[key - 0 + 2] = '';
        }
      });
      let value1 = filterObj[0]?.columnKey;
      let value2 = filterObj[1]?.columnKey;
      this.chartsData?.forEach(item => {
        if (item.date !== '合计' && item.date != '合计对比') {
          this.layoutChart[0].echartsOptions.xAxis[item.type].data.push(item.date);
          if (item.type == 0) {
            this.layoutChart[0].echartsOptions.series[0].data.push(item[value1] || 0);
            this.layoutChart[0].echartsOptions.series[1].data.push(item[value2] || 0);
          } else {
            this.layoutChart[0].echartsOptions.series[2].data.push(item[value1] || 0);
            this.layoutChart[0].echartsOptions.series[3].data.push(item[value2] || 0);
          }
        }
      });
      this.tableDatas.chartsLoading = false;
    },
    /** 获取图表数据
     * @param {*}
     * @return {*}
     */
    async getChartData() {
      this.tableDatas.chartsLoading = true;
      let postData = {
        page: '1',
        pageSize: '1000',
        ...this.filterCondition,
        groupBy: 'date',
        startDate: this.filterCondition.uploadTime[0].format(this.dateRangeFormat),
        endDate: this.filterCondition.uploadTime[1].format(this.dateRangeFormat),
      };
      if (this.showComparison) {
        postData.compareStartDate = this.filterCondition.compareTime[0].format(this.dateRangeFormat);
        postData.compareEndDate = this.filterCondition.compareTime[1].format(this.dateRangeFormat);
      } else {
        this.filterCondition.compareTime = [];
        delete postData.compareStartDate;
        delete postData.compareEndDate;
      }
      delete postData.filter;
      delete postData.uploadTime;
      delete postData.compareTime;
      delete postData.channelOption;
      let res = await this.layoutTable[0].getTableData(postData);
      if (res.code == 0) {
        let oData = res.oData?.records || [];
        let cData = res.cData?.records || [];
        oData.forEach(item => {
          item.type = 0;
        });
        cData.forEach(item => {
          item.type = 1;
        });
        if (this.showComparison && cData.length > 0) {
          this.chartsData = oData.reduce((arr, v, i) => {
            return arr.concat(v, cData[i]);
          }, []);
        } else {
          this.chartsData = oData;
        }
        this.setChartOptions();
      }
    },
    /** 获取数据
     * @param {*} val
     * @return {*}
     */
    async getData(val) {
      this.tableDatas.tableLoading = true;
      let postData = {
        page: this.tablePagination.page,
        pageSize: this.tablePagination.pageSize - 1,
        ...this.filterCondition,
        ...this.tableSorter,
        groupBy: this.tableDatas.groupBy,
        startDate: this.filterCondition.uploadTime[0].format(this.dateRangeFormat),
        endDate: this.filterCondition.uploadTime[1].format(this.dateRangeFormat),
      };
      if (this.showComparison) {
        postData.compareStartDate = this.filterCondition.compareTime[0].format(this.dateRangeFormat);
        postData.compareEndDate = this.filterCondition.compareTime[1].format(this.dateRangeFormat);
      } else {
        this.filterCondition.compareTime = [];
        delete postData.compareStartDate;
        delete postData.compareEndDate;
      }
      delete postData.filter;
      delete postData.uploadTime;
      delete postData.compareTime;
      delete postData.channelOption;
      let res = await this.layoutTable[0].getTableData(postData);
      if (res.code == 0) {
        let oData = res.oData?.records || [];
        let cData = res.cData?.records || [];
        oData.forEach(item => {
          item.type = 0;
          item.key = this.$UUID.v1();
        });
        cData.forEach(item => {
          item.key = this.$UUID.v1();
          item.type = 1;
        });
        if (this.showComparison && cData.length > 0) {
          this.tableDatas.tableData = oData.reduce((arr, v, i) => {
            return arr.concat(v, cData[i]);
          }, []);
        } else {
          this.tableDatas.tableData = oData;
        }
        let total = await getDetailReportAgg(postData);
        if (total.cData?.[0] && this.tableDatas.groupBy[0]) {
          total.cData[0][this.tableDatas.groupBy[0]] = '合计对比';
          total.cData[0].key = this.$UUID.v1();
          this.tableDatas.tableData.unshift(total.cData[0]);
        }
        if (total.oData?.[0] && this.tableDatas.groupBy[0]) {
          total.oData[0][this.tableDatas.groupBy[0]] = '合计';
          total.oData[0].key = this.$UUID.v1();
          this.tableDatas.tableData.unshift(total.oData[0]);
        }
        this.tablePagination.current = res.oData?.current;
        this.tablePagination.total = res.oData?.total;
        this.tablePagination.pageSize = this.showComparison ? res.oData?.size * 2 + 2 : res.oData?.size + 1;
      }
      this.tableDatas.tableLoading = false;
    },
    /** 筛选
     * @param {*}
     * @return {*}
     */
    onFilterBtnClick() {
      this.filterCondition.filter = new Array();
      this.layout.forEach(item => [
        item.children.forEach(childItem => {
          childItem.filterCondition = _.cloneDeep(this.filterCondition);
        }),
      ]);
      this.tablePagination.pageSize = this.filterCondition.uploadTime[1].diff(this.filterCondition.uploadTime[0], 'days') + 2;
      this.tableDatas.groupBy = this.showComparison ? ['date'] : _.cloneDeep(this.tableDatas.groupBy); // 维度变化请求列表
      this.getChartData();
    },
    /**重置筛选
     * @param {*}
     * @return {*}
     */
    onResetBtnClick() {
      this.filterCondition = {
        channel: this.channel,
        uploadTime: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
      };
      this.showComparison = false;
      this.tableDatas.groupBy = this.showComparison ? ['date'] : _.cloneDeep(this.tableDatas.groupBy); // 维度变化请求列表
      this.getChartData();
    },
    /** 禁止选择日期
     * @param {*} current
     * @return {*}
     */
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    /** 获取指标数据
     * @param {*}
     * @return {*}
     */
    getListSearchCaliber() {
      const _this = this;
      getColumns(this.filterCondition.channel).then(res => {
        res.data.forEach(item => {
          item.label = item.columnValue;
          item.value = item.columnKey;
        });
        this.layoutChart[0].selectOptions1 = res.data;
        this.layoutChart[0].selectOptions2 = res.data;
      });
    },

    /** 获取媒体账号
     * @param {*}
     * @return {*}
     */
    async getMediaAccountList() {
      let res = await getMediaAccountListData({ channelType: this.filterCondition.channel });
      this.mediaAccountStorage = res.page.list.map(item => ({
        label: item.advertiserName,
        value: item.advertiserId,
      }));
      this.mediaAccountOptions = this.mediaAccountStorage.slice(0, 100);
    },
    // 获取筛选部门数据
    async getDeptListByType() {
      let res = await getListByDeptTypesData({ deptType: ['OPERATION', 'VIDEO', 'OTHER'] });
      if (res.code === 0) {
        this.filterDepIdList = res.page?.records;
      }
    },
    /** 获取客户名称列表
     * @param {*}
     * @return {*}
     */
    async getCustomerList() {
      let res = await getCustomerDistinctListData({});
      this.companyNameOptions = res.page.list.map(item => ({
        label: item.customerOfCompanyName,
        value: item.companyCode,
      }));
    },
    // 筛选的产品列表
    async getProjectListFilter() {
      let res = await getProjectListByCompanyName({});
      if (res.code == 0) {
        this.projectStorage = res.page.list.map(item => ({
          label: item.projectName,
          value: item.projectCode,
        }));
        this.projectOptions = this.projectStorage.slice(0, 100);
      }
    },
    /** 获取运营人员列表
     * @param {*}
     * @return {*}
     */
    async getDistributionPerson() {
      let res = await getUserList({
        jobs: 'OPERATION',
      });
      if (res.code == 0) {
        this.distributionPerson = res.page.list;
      }
    },
    /** 获取开户主体列表
     * @param {*}
     * @return {*}
     */
    async getCustomersList() {
      let res = await getCustomersInvoiceList({});
      this.invoiceNameStorage = res.page.map(item => ({
        label: item.invoiceName,
        value: item.id,
      }));
      this.invoiceNameOptions = this.invoiceNameStorage.slice(0, 100);
    },
    /** 下拉列表搜索
     * @param {*} val
     * @param {*} options
     * @param {*} storage
     * @return {*}
     */
    onSelectSearch(val, options, storage) {
      let _this = this;
      if (!_this.timer) {
        _this.timer = setTimeout(function () {
          _this[options] = storage
            .filter(item => {
              return item.label.indexOf(val.trim()) > -1 || item.value == val;
            })
            .slice(0, 100);
          _this.timer = null;
        }, 100);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home_detail {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}
.home_card {
  background: #ffffff;
  border-radius: 4px;
}
:deep(.module_title) {
  line-height: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .title_content {
    font-size: 14px;
    font-weight: bold;
    margin-right: 14px;
    .anticon-down {
      margin-left: 14px;
      color: #666666;
    }
    &::before {
      content: '';
      display: inline-block;
      height: 12px;
      width: 4px;
      background-color: #2f70f4;
      margin-right: 10px;
      transform: translateY(1px);
    }
  }
  .select_group {
    @inputHeight: 30px;
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 12px;
    box-sizing: border-box;
    & > * {
      margin-right: 16px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-calendar-picker-input {
      height: @inputHeight;
    }
    .ant-select {
      width: 120px;
    }
    .ant-calendar-picker {
      width: 180px;
      .ant-calendar-picker-clear {
        right: 5px !important;
        margin-top: -6px !important;
      }
    }
  }
}
</style>
<style lang="less">
.home_detail {
  .detail_search_bar {
    .ant-tabs {
      padding: 0 24px;
    }
    .ant-tabs-bar {
      margin-bottom: 14px;
    }
  }
  .filter-container {
    padding: 0;
    padding-right: 16px;
  }
  .filter-content {
    .ant-form-item-label {
      line-height: 32px;
    }
    .ant-row.ant-form-item {
      margin-right: 0;
    }
  }
}
</style>
<style lang="less">
.ant-calendar-picker-input {
  height: 32px;
}
</style>